import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule, MatMenuItem } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { LayoutModule } from '@angular/cdk/layout';
import { BigInputComponent } from './big-input/big-input/big-input.component';
import { BigInputActionComponent } from './big-input/big-input-action/big-input-action.component';
import { RtlSupportDirective } from './rtl-support/rtl-support.directive';
import { AliveClient, VideoClient } from './http-clients/http-clients';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FilterByPipe } from './pipes/filter.pipe';
import { DateAdapter as SatDateAdapter, MAT_DATE_FORMATS as SAT_DATE_FORMATS, MAT_DATE_LOCALE as SAT_DATE_LOCALE, SatDatepickerModule } from 'saturn-datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faPlayCircle,
  faRocket,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook,
  faChevronDown,
  faSave,
  faUnlockAlt,
  faSearch,
  faCalendar,
  faEnvelope,
  faLock,
  faUnlock,
  faDownload,
  faEraser,
  faEye,
  faEyeSlash,
  faMinus,
  faFileImport,
  faInfo,
  faBuilding,
  faWalking,
  faFile,
  faClinicMedical,
  faNotesMedical,
  faUserNurse,
  faClock,
  faSlidersH,
  faMap,
  faList,
  faArrowLeft,
  faStop,
  faPhone,
  faMicrophoneSlash,
  faVideoSlash,
  faMicrophone,
  faVideo,
  faPaperPlane,
  faThumbtack,
  faArrowRight,
  faGlobe,
  faMapMarkerAlt,
  faCalendarPlus,
  faEllipsisV,
  faCalendarAlt,
  faUserMinus,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faMediumM, faTwitter, faInstagram, faYoutube, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { MatFormFieldRequiredDirective } from '@shared/directives/mat-form-field-required/mat-form-field-required.directive';
import { FiltersButtonComponent } from './components/filters-button/filters-button.component';
import { SpeedDialFabComponent } from './components/speed-dial-fab/speed-dial-fab.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SexService } from './services/sex.service';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { MatFormFieldClearButtonComponent } from './components/mat-form-field-clear-button/mat-form-field-clear-button.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { LoginDataService } from '@features/login/login-data.service';
import { ResetPasswordService } from './services/resetpassword.service';
import { ResetPasswordDataService } from './services/resetpassworddata.service';
import { MatBadgeModule } from '@angular/material/badge';
import { CachedInnerHtmlDirective } from './directives/cached-inner-html/cached-inner-html.directive';
import { VideoChatService } from '../features/video-chat/services/video-chat.service';
import { ConfirmDialogService } from '@core/notifications/confirm-dialog-notification/confirm-dialog-notification.service';
import { LoginService } from '@features/login/login.service';
import { MatPaginatorI18nService } from './mat-paginator-i18n.service';
import { PasswordStrengthBarComponent } from './components/password-strength-bar/password-strength-bar.component';
import { LocationFormComponent } from './components/location-form/location-form.component';
import { AgmCoreModule } from '@agm/core';
import { TimeSlotComponent } from '@features/add-booking/time-slot/time-slot.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { environment } from 'environments/environment';

export const KailoMatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCardModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    LayoutModule,
    MatPaginatorModule,
    CKEditorModule,
    MatRadioModule,
    MatSelectModule,
    FontAwesomeModule,
    MatDatepickerModule,
    NgxFilesizeModule,
    NgxMaterialTimepickerModule,
    SatDatepickerModule,
    ReactiveFormsModule,
    MatBadgeModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      language: 'en',
      libraries: ['geometry', 'places']
    })
  ],
  declarations: [
    BigInputComponent,
    BigInputActionComponent,
    RtlSupportDirective,
    FilterByPipe,
    MatFormFieldRequiredDirective,
    FiltersButtonComponent,
    SpeedDialFabComponent,
    BackButtonComponent,
    HighlightSearchPipe,
    MatFormFieldClearButtonComponent,
    ResetPasswordComponent,
    CachedInnerHtmlDirective,
    PasswordStrengthBarComponent,
    LocationFormComponent,
    TimeSlotComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSliderModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    NgxMatSelectSearchModule,
    MatExpansionModule,
    LayoutModule,
    MatPaginatorModule,
    CKEditorModule,
    MatRadioModule,
    MatSelectModule,
    NgxFilesizeModule,
    FontAwesomeModule,
    BigInputComponent,
    BigInputActionComponent,
    RtlSupportDirective,
    NgxPermissionsModule,
    FilterByPipe,
    SatDatepickerModule,
    MatFormFieldRequiredDirective,
    FiltersButtonComponent,
    SpeedDialFabComponent,
    BackButtonComponent,
    HighlightSearchPipe,
    MatFormFieldClearButtonComponent,
    ResetPasswordComponent,
    MatBadgeModule,
    CachedInnerHtmlDirective,
    PasswordStrengthBarComponent,
    LocationFormComponent,
    TimeSlotComponent,
  ],
  providers: [
    AliveClient,
    NgDialogAnimationService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: KailoMatDateFormats },
    { provide: SatDateAdapter, useClass: MomentDateAdapter, deps: [SAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: SAT_DATE_FORMATS, useValue: KailoMatDateFormats },
    SexService,
    ResetPasswordService,
    LoginService,
    ResetPasswordDataService,
    LoginDataService,
    VideoChatService,
    VideoClient,
    ConfirmDialogService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faBars,
      faUserCircle,
      faPowerOff,
      faCog,
      faRocket,
      faPlayCircle,
      faGithub,
      faMediumM,
      faTwitter,
      faInstagram,
      faYoutube,
      faPlus,
      faEdit,
      faTrash,
      faTimes,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faStream,
      faBook,
      faChevronDown,
      faSave,
      faUnlockAlt,
      faSearch,
      faCalendar,
      faEnvelope,
      faLock,
      faUnlock,
      faDownload,
      faEraser,
      faEye,
      faEyeSlash,
      faMinus,
      faEdit,
      faFileImport,
      faInfo,
      faBuilding,
      faWalking,
      faSlidersH,
      faFile,
      faClock,
      faNotesMedical,
      faClinicMedical,
      faUserNurse,
      faMap,
      faList,
      faArrowLeft,
      faStop,
      faPhone,
      faMicrophone,
      faMicrophoneSlash,
      faVideo,
      faVideoSlash,
      faPaperPlane,
      faThumbtack,
      faArrowRight,
      faGlobe,
      faMapMarkerAlt,
      faFacebookF,
      faLinkedinIn,
      faCalendarPlus,
      faEllipsisV,
      faCalendarAlt,
      faUserMinus,
      faFileDownload
    );
  }
}

