import { Moment } from 'moment';
import * as moment from 'moment';

export function mapDateOfBirthToAge(dateOfBirth: Moment): number {
  return moment().diff(dateOfBirth, 'years');
}

export function combineMomentDateAndTimeSpan(date: Moment, time: string): Moment {
  const split = time.split(':'); 

  let value = date.utcOffset(120).clone().startOf('day').add({  
    hours: +split[0],
    minutes: +split[1]
  });

  return value;
}

export function isNullOrWhitespace(input) {
  return !input || !input.trim();
}